<template>
  <a-modal
    title="从图库添加"
    v-model="visible"
    width="1000px"
    :dialog-style="{ top: '20px', left: '100px' }"
    @ok="save"
    @cancel="onClose"
    :maskClosable="true"
  >
    <div class="search-list">
      <a-select
        v-model="form.principalId"
        placeholder="请选择品牌"
        style="flex: 1"
        show-search
        option-filter-prop="children"
        @change="handlePrincipalChange"
        allowClear
      >
        <a-select-option v-for="item in principalList" :key="item.principalId" :value="item.principalId">{{
          item.principalName
        }}</a-select-option>
      </a-select>
      <PicFolderTree
        ref="PicFolderTree"
        style="flex: 1; margin: 0 10px"
        :treeDefaultExpandAll="true"
        v-model="form.folderId"
        @input="handleFolderChange"
        allowClear
      />
      <a-select
        v-model="form.vehicleSeriesIds"
        mode="multiple"
        placeholder="请选择车系"
        option-filter-prop="children"
        style="flex: 1"
        allowClear
        @change="getPicList(true)"
      >
        <a-select-option v-for="item in seriesList" :value="item.id" :key="item.id">{{ item.name }}</a-select-option>
      </a-select>
    </div>
    <a-spin :spinning="loading">
      <div class="pic-container">
        <div class="pic-line">
          <div
            class="pic-item"
            v-for="item in picList"
            :key="item.id"
            :style="{ 'background-image': `url(${item.file.previewUrl})` }"
            @click="handlePicPreview([item.file.fileUrl])"
          >
            <a-checkbox
              class="checkbox"
              @click.stop="() => {}"
              @change="onCheckChange(item)"
              :checked="item.checked"
            ></a-checkbox>
          </div>
        </div>
        <div v-show="!picList.length" class="tips">暂无数据</div>
      </div>
      <base-pagination
        v-show="picList.length"
        :currentPage="pagination.current"
        :pageSize="pagination.pageSize"
        :options="['20', '50', '100']"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
      />
    </a-spin>
  </a-modal>
</template>

<script>
import { api as viewerApi } from 'v-viewer';
import PicFolderTree from '@/components/xhsAgencyTool/PicFolderTree.vue';
import api from '@/pages/app/xhsAgency/xhsPicStore/api.js';

export default {
  name: 'picStoreListModal',
  components: { PicFolderTree },
  props: { isReplace: { type: Boolean, default: false } },
  data() {
    return {
      principalList: [],
      seriesList: [],
      form: {
        principalId: undefined,
        seriesData: undefined,
        folderId: undefined,
      },
      visible: false,
      saveLoading: false,
      loading: false,
      picList: [],
      pagination: {
        current: 1,
        pageSize: 20,
        total: 0,
      },
      chooseList: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getBrandList();
      this.getPicList();
    },
    onOpen() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
      this.handleClearChoose();
    },
    async getPicList(init) {
      this.picList = [];
      if (init) this.pagination.current = 1;
      const params = {
        ...this.form,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      if (params.principalId) {
        params.principalIds = [this.form.principalId];
        delete params.principalId;
      }
      this.loading = true;
      const { code, data, message } = await api.getXhsPicList(params).finally(() => (this.loading = false));
      if (code === 200) {
        this.picList = data.list.map((item) => {
          let previewUrl = '';
          if (item.file.fileUrl) {
            if (
              item.file.fileUrl.indexOf('xhscdn') === -1 &&
              item.file.fileUrl.indexOf('snapshot') == -1 &&
              (item.file.fileUrl.indexOf('content') > -1 || item.file.fileUrl.indexOf('afanti') > -1)
            ) {
              if (item.file.fileUrl.indexOf('x-oss-process') > -1) {
                previewUrl = item.file.fileUrl + `/resize,l_220,m_lfit`;
              } else {
                previewUrl = item.file.fileUrl + `?x-oss-process=image/resize,l_220,m_lfit`;
              }
            }
          }
          item.file.previewUrl = previewUrl;
          item.checked = false;
          return { ...item };
        });
        this.pagination.total = data.total;
      } else {
        this.$message.error(message);
      }
    },
    handlePaginationChange(current, pageSize) {
      this.chooseList = [];
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getPicList();
    },
    getBrandList() {
      this.handleRequest(
        'getBrandManageList',
        (data) => {
          this.principalList = data.list;
        },
        { size: 999, page: 1 }
      );
    },
    handlePrincipalChange(val) {
      this.form.folderId = undefined;
      this.form.seriesData = undefined;
      this.seriesList = [];
      this.$refs.PicFolderTree.clearList();
      if (val) {
        this.$refs.PicFolderTree.getFolderList(this.form.principalId);
        this.getSeriesList();
      }
      this.getPicList(true);
    },
    handleFolderChange(folderId) {
      this.form.folderId = folderId?.length ? folderId : undefined;
      this.getPicList(true);
    },
    getSeriesList() {
      if (this.form.principalId) {
        this.handleRequest(
          'getNewTypeList',
          (data) => {
            this.seriesList = data;
          },
          { principalIds: this.form.principalId }
        );
      }
    },
    save() {
      if (!this.chooseList.length) return this.$message.info('请选择图片');
      if (this.isReplace && this.chooseList.length !== 1) return this.$message.info('替换仅支持单张图片');
      this.$emit('addPic', this.chooseList);
      this.onClose();
    },
    handlePicPreview(images) {
      const $viewer = this.$viewerApi({
        options: {
          toolbar: true,
          index: 0,
        },
        images,
      });
    },
    onCheckChange(value) {
      if (!value.checked) {
        // 选择图片
        if (this.isReplace && this.chooseList.length === 1) return this.$message.info('替换仅支持单张图片');
        this.chooseList.push({
          fileUrl: value.file.fileUrl,
          fileId: value.file.id,
        });
      } else {
        // 取消图片
        const index = this.chooseList.findIndex((val) => val.fileUrl == value.file.fileUrl);
        this.chooseList.splice(index, 1);
      }

      this.picList.forEach((val) => {
        if (val.id === value.id) {
          val.checked = !val.checked;
        }
      });
    },
    handleClearChoose() {
      this.chooseList = [];
      if (this.picList.length) {
        this.picList.forEach((val) => {
          val.checked = false;
        });
      }
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      return new Promise(async (resolve) => {
        const { code, data, message } = await api[APINAME](PARAMS);
        if (code === 200) {
          callbackFn(data);
          resolve();
        } else {
          this.$message.error(`${message}`);
          resolve();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.search-list {
  display: flex;
  margin-bottom: 10px;
}

.pic-container {
  min-height: 200px;
  max-height: 550px;
  overflow-y: auto;

  .pic-line {
    display: grid;
    padding: 15px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px 10px;

    .pic-item {
      position: relative;
      width: 100%;
      height: 20vh;
      min-height: 200px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      background-size: contain;
      background-position: center center;
      background-color: #000;

      .checkbox {
        position: absolute;
        top: 3px;
        right: 3px;
        line-height: 0;
        z-index: 2;
        /deep/ .ant-checkbox-inner {
          width: 30px;
          height: 30px;
        }
        /deep/ .ant-checkbox-inner::after {
          width: 8px;
          height: 14px;
        }
      }
    }
  }

  .tips {
    height: 200px;
    line-height: 200px;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
  }
}
</style>
